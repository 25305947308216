<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataSop.sop_category ? dataSop.sop_category : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Sub-Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataSop.name ? dataSop.name : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Terakhir Diubah
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataSop.updated_at ? formatDate(dataSop.updated_at) : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Diubah Oleh
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                class="uk-input"
                :value="dataSop.updated_by ? dataSop.updated_by : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
      </div>
      <br>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
          Content
        </div>
        <div class="uk-width">
          <vue-ckeditor
            type="classic"
            :value="dataSop.content ? dataSop.content : '-'"
            :editors="editors"
            :readonly="true"
          />
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </button>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl uk-text-right">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="showDeleteConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/trash-white.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Hapus
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToEdit"
          >
            <img
              v-lazy="`${images}/icon/edit.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Ubah
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import VueCkeditor from 'vue-ckeditor5'

export default {
  components: {
    'vue-ckeditor': VueCkeditor.component
  },
  props: {
    dataSop: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      value1: 'hello',
      editors: {
        classic: ClassicEditor
      }
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations({
      setModalDelete: 'detailSop/SET_MODAL_DELETE'
    }),
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    goToBack() {
      this.$router.push(`/admin/detail-sop`)
    },
    goToEdit() {
      this.$router.push(`/admin/detail-sop/edit/${this.dataSop.id}`)
    },
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.dataSop)
    }
  }
}
</script>
