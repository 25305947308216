<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Detail "{{ sop.name }}"
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data-sop="sop" />
    <div id="container-modal-delete" />
    <modal-delete-confirm />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalDeleteConfirm from './ModalDeleteConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalDeleteConfirm,
    BreadCrumb
  },
  data() {
    return {
      sop: {},
      breadcrumb: [
        {
          link: '/admin/detail-sop',
          title: 'Detail SOP'
        },
        {
          link: null,
          title: 'Detail'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      detailSopDetail: 'detailSop/detailSopDetail'
    })
  },
  async mounted() {
    await this.getDetailSopDetail(this.$route.params.id)
    this.sop = this.detailSopDetail.data
  },
  methods: {
    ...mapActions({
      getDetailSopDetail: 'detailSop/getDetailSopDetail'
    })
  }
}
</script>
